import { isNull, isNullOrUndefined } from "is-what";
import indicatorDict from "../../helper/indicator_service.json";

const ScreenerService = {
  dictKeyExists: (key, array) => {
    var flag = false;
    Object.keys(array).map(function (arraykey, arrayindex) {
      if (key == arraykey) {
        flag = true;
      }
    });
    return flag;
  },
  checkParamSetting: (params) => {
    if (params == "" || params == null) {
      return "";
    } else if (Object.keys(params).length == 0) {
      return "";
    } else {
      var paramSettingData = [];

      if (ScreenerService.dictKeyExists("source", params)) {
        paramSettingData = [
          ...paramSettingData,
          indicatorDict[params["source"]]["name"],
        ];
      }
      if (ScreenerService.dictKeyExists("std-deviation", params)) {
        paramSettingData = [...paramSettingData, params["std-deviation"]];
      }
      if (ScreenerService.dictKeyExists("k", params)) {
        paramSettingData = [...paramSettingData, params["k"]];
      }
      if (ScreenerService.dictKeyExists("d", params)) {
        paramSettingData = [...paramSettingData, params["d"]];
      }
      if (ScreenerService.dictKeyExists("smooth", params)) {
        paramSettingData = [...paramSettingData, params["smooth"]];
      }
      if (ScreenerService.dictKeyExists("fast_length", params)) {
        paramSettingData = [...paramSettingData, params["fast_length"]];
      }
      if (ScreenerService.dictKeyExists("slow_length", params)) {
        paramSettingData = [...paramSettingData, params["slow_length"]];
      }
      if (ScreenerService.dictKeyExists("signal_length", params)) {
        paramSettingData = [...paramSettingData, params["signal_length"]];
      }
      if (ScreenerService.dictKeyExists("length", params)) {
        paramSettingData = [...paramSettingData, params["length"]];
      }
      if (ScreenerService.dictKeyExists("offset", params)) {
        var offsetdata = params["offset"] + " candles ago";
        paramSettingData = [...paramSettingData, offsetdata];
      }
      if (ScreenerService.dictKeyExists("number", params)) {
        paramSettingData = [
          ...paramSettingData,
          params["number"].toLocaleString("en-IN"),
        ];
      }
      var IndparamSettingData = paramSettingData.join(", ");
      return IndparamSettingData;
    }
  },
  paramsSettings: (params) => {
    var paramSettingData = [];

    if ("source" in params) {
      paramSettingData = [
        ...paramSettingData,
        indicatorDict[params["source"]]["name"],
      ];
    }
    if ("std-deviation" in params) {
      paramSettingData = [...paramSettingData, params["std-deviation"]];
    }
    if ("k" in params) {
      paramSettingData = [...paramSettingData, params["k"]];
    }
    if ("d" in params) {
      paramSettingData = [...paramSettingData, params["d"]];
    }
    if ("smooth" in params) {
      paramSettingData = [...paramSettingData, params["smooth"]];
    }
    if ("fast_length" in params) {
      paramSettingData = [...paramSettingData, params["fast_length"]];
    }
    if ("slow_length" in params) {
      paramSettingData = [...paramSettingData, params["slow_length"]];
    }
    if ("signal_length" in params) {
      paramSettingData = [...paramSettingData, params["signal_length"]];
    }
    if ("length" in params) {
      paramSettingData = [...paramSettingData, params["length"]];
    }
    if ("offset" in params) {
      var offsetdata = params["offset"] + " candles ago";
      paramSettingData = [...paramSettingData, offsetdata];
    }
    return paramSettingData;
  },
  IndparamsSettings: (params) => {
    var paramSettingData = [];
    if ("source" in params) {
      paramSettingData = [
        ...paramSettingData,
        indicatorDict[params["source"]]["name"],
      ];
    }
    if ("std-deviation" in params) {
      paramSettingData = [...paramSettingData, params["std-deviation"]];
    }
    if ("k" in params) {
      paramSettingData = [...paramSettingData, params["k"]];
    }
    if ("d" in params) {
      paramSettingData = [...paramSettingData, params["d"]];
    }
    if ("smooth" in params) {
      paramSettingData = [...paramSettingData, params["smooth"]];
    }
    if ("fast_length" in params) {
      paramSettingData = [...paramSettingData, params["fast_length"]];
    }
    if ("slow_length" in params) {
      paramSettingData = [...paramSettingData, params["slow_length"]];
    }
    if ("signal_length" in params) {
      paramSettingData = [...paramSettingData, params["signal_length"]];
    }
    if ("length" in params) {
      paramSettingData = [...paramSettingData, params["length"]];
    }
    if ("offset" in params) {
      var offsetdata = params["offset"] + " candles ago";
      paramSettingData = [...paramSettingData, offsetdata];
    }
    if ("number" in params) {
      paramSettingData = [
        ...paramSettingData,
        params["number"].toLocaleString("en-IN"),
      ];
    }
    var IndparamSettingData = "(" + paramSettingData.join(", ") + ")";
    return IndparamSettingData;
  },
  comparatortimes_to_percentage: (times) => {
    var percentage_data = times * 100 - 100;
    return Math.round(percentage_data);
  },
  comparatorParamsSettings: (params, comparator, comparatorData) => {

    let termData = "";
    if (!isNullOrUndefined(comparatorData)) {
      if (comparatorData.hasOwnProperty("termName")) {
        termData = comparatorData["termName"];
      }
    }

    var comparatorfinalparamsset = "";
    if (Object.keys(params).length != 0) {
      if (params["times"] != 1) {
        comparatorfinalparamsset =
          comparatorfinalparamsset + params["times"] + " times";
          // ScreenerService.comparatortimes_to_percentage(params["times"]) +
          // "% ";
        if (params["range"] != 0) {
          comparatorfinalparamsset =
            comparatorfinalparamsset + "for the last " + params["range"] + " candle(s)";
        }
      } else {
        if (params["range"] != 0) {
          
          comparatorfinalparamsset =
            comparatorfinalparamsset + "for the last " + params["range"] + " candle(s)";
          
        }
      }
    }
    
    if (comparatorfinalparamsset == "") {
      if (
        termData == "Greater Than For N Number" ||
        termData == "Lesser Than For N Number"
      ) {
        
        comparatorfinalparamsset = comparatorfinalparamsset + "For 0 candle(s)";
      }
      if (termData == "N % Greater Than" || termData == "N% Lesser Than") {
        comparatorfinalparamsset = comparatorfinalparamsset + "";
      }
    }
    if(comparatorfinalparamsset.includes("for the last")){
      comparatorfinalparamsset = comparator+" "+comparatorfinalparamsset;
    }else{
      comparatorfinalparamsset = comparator+" " +comparatorfinalparamsset;
    }
    // comparatorfinalparamsset = comparatorfinalparamsset + " " + comparator;
    if(comparatorfinalparamsset.includes("Is") && !comparatorfinalparamsset.includes("For")){
      comparatorfinalparamsset = "Is " +comparatorfinalparamsset.replace('Is', '');
    }
    return comparatorfinalparamsset;
  },
  checkLogin: () => {
    const value = localStorage.getItem("user");
    return !!value ? JSON.parse(value) : undefined;
  },
};

export default ScreenerService;
