import "../styles/globals.css";
import "reflect-metadata";
import "../styles.css";
import "../styles/loader.scss";
import { Fragment } from "react";
import { useEffect, useState } from "react";
import AppContext from "../AppContext";
import IdleTimeOutHandler from "../components/idle/idleTimer";
import { useRouter } from "next/router";
import Head from "next/head";
import dynamic from "next/dynamic";
import { pageview } from "../lib/analytics";

import Image from "next/image";

const AuthContextProvider = dynamic(() => import("../contexts/AuthContext"));

import popularScansList from "../helper/popular_scan_list.json";
const FooterLayout = dynamic(() => import("../components/footer/footerlayout"));
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import Privacypolicy from "../components/footer/privacypolicy";
import Tos from "../components/footer/tos";
import axios from "axios";
import UserAPI from "./api/user";
import exchangeList from "../helper/exchanges.json";
import useSWR from "swr";
import storage from "../utils/storage";
import checkLogin from "../utils/checkLogin";
import ScreenerService from "./api/screener_service";

import { NextSeo } from "next-seo";

var CryptoJS = require("crypto-js");

const jwt = require("jsonwebtoken");
import getConfig from "next/config";
// import LazyLoad from "react-lazyload";
const { publicRuntimeConfig } = getConfig();

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [buttonType, setbuttonType] = useState(true);
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      console.log("gtag off");
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const handleClick = (e, path) => {
    if (path === "/coins") {
      setbuttonType(true);
    }
    if (path === "/posts") {
    }
  };
  useEffect(() => {
    seturlpathname(window.location.pathname);
  });

  function chooseCoin() {
    setbuttonType(false);
    router.push("/coin");
  }
  const [binance_ticker, setbinance_ticker] = useState([]);
  const [screenedResultState, setscreenedResultState] = useState([]);
  const [languageSelected, setLanguageSelected] = useState("en");
  const [coinPairState, setcoinPairState] = useState([]);
  const [sortedResult, setsortedResult] = useState([]);
  const [totalCoins, settotalCoins] = useState(0);
  const [screenertotalCoins, setscreenertotalCoins] = useState(0);
  const [defaultQuote, setdefaultQuote] = useState("");
  const [tempList, settempList] = useState([]);
  const [tickerDict, settickerDict] = useState({});
  const [savedScreenerList, setsavedScreenerList] = useState([]);
  const [itemList, setitemList] = useState(popularScansList);
  const [conditionData, setconditionData] = useState([]);
  const [viewscreener, setviewscreener] = useState([]);
  const [savescan, setsavescan] = useState([]);
  const [popularScanDict, setpopularScanDict] = useState({
    popular: popularScansList,
    saved: [],
    coin: [],
  });
  const [userLogin, setuserLogin] = useState(null);
  const [aboutus, setaboutus] = useState(false);
  const [privacypolicy, setprivacypolicy] = useState(false);
  const [tos, settos] = useState(false);
  const [plans, setplans] = useState(false);
  const [selectedExchange, setselectedExchange] = useState("Binance");
  const [selectedQuote, setselectedQuote] = useState("ALL");

  const [ipDetails, setIpDetails] = useState("");
  const [token, settoken] = useState("455555");

  const [showSaveBtn, setshowSaveBtn] = useState(false);

  const [selectedScan, setselectedScan] = useState("popular");
  const [chartData, setchartData] = useState({});

  const [chartDict, setchartDict] = useState("");
  const [urlpathname, seturlpathname] = useState("");

  const [savedAlertList, setsavedAlertList] = useState([]);
  const [windowHeight, setWindowHeight] = useState(0);

  const [alertScanDict, setalertScanDict] = useState({
    saved: [],
    notifications: [],
    remindin: "one_time",
    id: "",
    notification_track: [],
    notifications_all: [],
    notifications_byId: [],
    notification_by_exchange_symbol: [],
  });
  const [selectedAlert, setselectedAlert] = useState("saved");
  const [alertconditionData, setalertconditionData] = useState([]);

  const [isActive, setIsActive] = useState(true);
  const [isLogout, setLogout] = useState(false);
  const [delta, setdelta] = useState("Delta");
  const [deltaDays, setdeltaDays] = useState(0);
  const [deltaHours, setdeltaHours] = useState(0);
  const [saved_screener_id, setsaved_screener_id] = useState(null);

  async function generateToken(ipaddress) {
    const token = jwt.sign(
      { password: "tradetoken", tradetokensecurity: "tradetokensecurity" },
      publicRuntimeConfig.secret,

      { expiresIn: 100 }
    );

    localStorage.setItem("jwt_access_token", token);
  }

  useEffect(() => {
    console.log(process.env.NODE_ENV, "____")
    axios.get("https://ipapi.co/json/").then((res) => {
      setIpDetails(res.data.ip);
    });
    const token = jwt.sign(
      { password: "tradetoken", tradetokensecurity: "tradetokensecurity" },
      publicRuntimeConfig.secret,

      { expiresIn: 100 }
    );

    localStorage.setItem("jwt_access_token", token);
  }, []);

  useEffect(() => {
    exchangeInfoDetails();
  }, []);


  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    // Add event listener to update height on window resize
    window.addEventListener('resize', handleResize);

    // Initial height on component mount
    setWindowHeight(window.innerHeight);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const MINUTE_MS = 120000;
  const ONE_MINUTE_MS = 60000;

  async function coin_background(exchange_data) {
    for (var i = 0; i < exchangeList.length; i++) {
      if (exchangeList[i] == "binance") {
        const { data, status } = await UserAPI.binanceTicker(token);

        if (status !== 200) {
        } else {
          setbinance_ticker(data);
          await getSortedData(data, exchangeList[i], exchange_data);
        }
      }
      if (exchangeList[i] == "hitbtc") {
        const { data, status } = await UserAPI.hitbtcTicker(token);

        if (status !== 200) {
        } else {
          await getSortedData(data, exchangeList[i], exchange_data);
        }
      }
    }
  }

  async function exchangeInfoDetails() {
    const { data, status } = await UserAPI.exchangeInfo(token);
    if (status !== 200) {
    } else {
      setcoinPairState(data["data"]["shrimpy_exchangeInfo"]);
      coin_background(data["data"]["shrimpy_exchangeInfo"]);
    }
  }
  async function getSortedData(data, exchange, exchange_data) {
    var resultSet = {};
    data.map((e, index) => {
      var getSymbolIndex = exchange_data.findIndex(
        (p) => p.symbol == e["symbol"]
      );
      if (getSymbolIndex >= 0) {
        var getQuote = exchange_data[getSymbolIndex]["quoteTradingSymbol"];
        if (getQuote in resultSet) {
          resultSet[getQuote].push(e);
        } else {
          resultSet[getQuote] = [e];
        }
      }
    });

    const resultSetkeyLength = {};
    var getQuote = "";
    Object.keys(resultSet).map(function (resultSetKey, resultSetIndex) {
      resultSetkeyLength[resultSetKey] = resultSet[resultSetKey].length;
    });
    var sortedList = {};
    Object.keys(resultSetkeyLength)
      .sort((a, b) => resultSetkeyLength[b] - resultSetkeyLength[a])
      .forEach((key) => {
        sortedList[key] = resultSetkeyLength[key];
      });

    let tickerResultData = {};
    let selectedQuote = "";
    Object.keys(sortedList).map(function (sortedKey, sortedIndex) {
      if (sortedIndex == 0) {
        getQuote = sortedKey;
        selectedQuote = sortedKey;
      }
      tickerResultData[sortedKey] = resultSet[sortedKey];
    });
    settotalCoins(data.length);

    var sortedtempList = tickerResultData[getQuote];

    sortedtempList.sort(function (first, second) {
      return second.volume - first.volume;
    });

    var exchangeFinalDict = {};
    exchangeFinalDict[exchange] = tickerResultData;
    exchangeFinalDict[exchange + "_tempList"] = tickerResultData[getQuote];
    exchangeFinalDict[exchange + "_quoteAsset"] = getQuote;
    var exchangetempList = exchange + "_tempList";
    var exchangeQuote = exchange + "_quoteAsset";

    if (exchange == "binance") {
      settickerDict((prevState) => ({
        ...prevState,
        binance: tickerResultData,
        binancetempList: tickerResultData[getQuote],
        binanceQuote: getQuote,
        binanceTotalCoin: data.length,
        data: data,
      }));
    }
    if (exchange == "hitbtc") {
      settickerDict((prevState) => ({
        ...prevState,
        hitbtc: tickerResultData,
        hitbtctempList: tickerResultData[getQuote],
        hitbtcQuote: getQuote,
        hitbtcTotalCoin: data.length,
        data: data,
      }));
    }
  }
  const { data: currentUser } = useSWR("user", storage);
  const isLoggedIn = checkLogin(currentUser);

  async function getSavedScreener() {
    const { data, status } = await UserAPI.savedScreener();
    if (status !== 200) {
    } else {
      setsavedScreenerList([]);
      if (ScreenerService.dictKeyExists("error", data)) {
      } else {
        setsavedScreenerList(data);
      }
    }
  }

  useEffect(() => {
    const one_minute_interval = setInterval(() => {
      console.log(
        "Every 1 MInutes Generate Token",
        window.localStorage.getItem("active")
      );

      const user: any = JSON.parse(window.localStorage.getItem("user"));
      if (window.location.pathname != "/") {
        if (user) {
          if (window.localStorage.getItem("active") == "1") {
            getSavedScreener();
          }
        }

        generateToken(ipDetails);
      }
    }, ONE_MINUTE_MS);

    return () => clearInterval(one_minute_interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Every 2 Minutes", isActive);

      if (window.location.pathname != "/") {
       
        if (coinPairState.length == 0) {
          if (window.localStorage.getItem("active") == "1") {
            exchangeInfoDetails();
          }
        } else {
          if (window.localStorage.getItem("active") == "1") {
            coin_background(coinPairState);
          }
        }
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  return (
    <AppContext.Provider
      value={{
        state: {
          languageSelected: languageSelected,
          screenedResultState: screenedResultState,
          coinPairState: coinPairState,
          sortedResult: sortedResult,
          totalCoins: totalCoins,
          defaultQuote: defaultQuote,
          tempList: tempList,
          tickerDict: tickerDict,
          savedScreenerList: savedScreenerList,
          itemList: itemList,
          conditionData: conditionData,
          viewscreener: viewscreener,
          savescan: savescan,
          popularScanDict: popularScanDict,
          userLogin: userLogin,
          selectedExchange: selectedExchange,
          selectedQuote: selectedQuote,
          ipDetails: ipDetails,
          token: token,
          showSaveBtn: showSaveBtn,
          selectedScan: selectedScan,
          setchartData: setchartData,
          savedAlertList: savedAlertList,
          alertScanDict: alertScanDict,
          selectedAlert: selectedAlert,
          alertconditionData: alertconditionData,
          screenertotalCoins: screenertotalCoins,
          delta: delta,
          deltaDays: deltaDays,
          deltaHours: deltaHours,
          saved_screener_id: saved_screener_id,
          binance_ticker: binance_ticker,
        },
        setLanguageSelected: setLanguageSelected,
        setscreenedResultState: setscreenedResultState,
        setcoinPairState: setcoinPairState,
        setsortedResult: setsortedResult,
        settotalCoins: settotalCoins,
        setdefaultQuote: setdefaultQuote,
        settempList: settempList,
        settickerDict: settickerDict,
        setsavedScreenerList: setsavedScreenerList,
        setitemList: setitemList,
        setconditionData: setconditionData,
        setviewscreener: setviewscreener,
        setsavescan: setsavescan,
        setpopularScanDict: setpopularScanDict,
        setuserLogin: setuserLogin,
        setselectedExchange: setselectedExchange,
        setselectedQuote: setselectedQuote,
        setIpDetails: setIpDetails,
        settoken: settoken,
        setshowSaveBtn: setshowSaveBtn,
        setselectedScan: setselectedScan,
        setchartData: setchartData,
        setchartDict: setchartDict,
        setsavedAlertList: setsavedAlertList,
        setalertScanDict: setalertScanDict,
        setselectedAlert: setselectedAlert,
        setalertconditionData: setalertconditionData,
        setscreenertotalCoins: setscreenertotalCoins,
        setdelta: setdelta,
        setdeltaDays: setdeltaDays,
        setdeltaHours: setdeltaHours,
        setsaved_screener_id: setsaved_screener_id,
        setbinance_ticker: setbinance_ticker,
      }}
    >
      <AuthContextProvider>
        <Fragment>
          <IdleTimeOutHandler
            onActive={() => {
              setIsActive(true);
            }}
            onIdle={() => {
              setIsActive(false);
            }}
            onLogout={() => {
              setLogout(true);
            }}
          />
          <Head>
            {/* <script
              defer
              data-domain="tradeplan.io"
              src="https://analytics.tradeplan.io/js/plausible.js"
            ></script>

            <script
              defer
              src={`https://www.googletagmanager.com/gtag/js?id=G-TB8XPY2YDJ`}
            /> */}

            <title>
              TradePlan | Crypto technical indicator scanner | Crypto technical
              indicator screener | Bitcoin technical indicator scanner | Bitcoin
              technical indicator screener
            </title>

            <meta charSet="UTF-8"></meta>
            <meta content="IE=Edge" httpEquiv="X-UA-Compatible"></meta>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            ></meta>

            <meta
              name="keywords"
              content="Bitcoin, Tradeplan, Tradeplan.io, Binance, HitBTC, SMA, EMA, WMA, RSI, Bollinger Band Upper, Bollinger Band Lower, Stochastic K, Stochastic D, MACD Signal, MACD Line, MACD Histogram,crypto scanner,crypto screener,bitcoin scanner,bitcoin screener,crypto technical indicator scanner,crypto technical indicator screener,bitcoin technical indicator scanner,bitcoin technical indicator screener,binance scanner,binance screener,crypto moving average scanner,crypto moving average screener,bitcoin moving average scanner,bitcoin moving average screener,crypto rsi scanner,crypto rsi screener,bitcoin rsi scanner,bitcoin rsi screener,volume pump scanner,volume pump screener,crypto technical indicator alerts,bitcoin technical indicator alerts"
            ></meta>
            <meta
              name="description"
              content="Crypto Scanner powered by technical indicators, Crypto technical indicator scanner,Crypto technical indicator screener,Bitcoin technical indicator scanner,Bitcoin technical indicator screener"
            ></meta>
            <link rel="icon" href="/tplogoblack.png" />
            <link
              rel="shortcut icon"
              type="/png"
              href="/tp_logo_black_white.png"
            />
            <link rel="canonical" href="https://tradeplan.io" />
            {/* OpenGraph meta tags */}
            <meta property="og:title" content="TradePlan | Crypto technical indicator scanner | Crypto technical
              indicator screener | Bitcoin technical indicator scanner | Bitcoin
              technical indicator screener" />
            <meta property="og:description" content="Crypto Scanner powered by technical indicators, Crypto technical indicator scanner,Crypto technical indicator screener,Bitcoin technical indicator scanner,Bitcoin technical indicator screener" />
            <meta property="og:image" content="https://tradeplan.io/tplogoblack.png" />
            <meta property="og:url" content="https://tradeplan.io" />
            <meta property="og:type" content="website" />
            <NextSeo
              title="TradePlan | Crypto technical indicator scanner | Crypto technical
              indicator screener | Bitcoin technical indicator scanner | Bitcoin
              technical indicator screener"
              description="Crypto Scanner powered by technical indicators, Crypto technical indicator scanner,Crypto technical indicator screener,Bitcoin technical indicator scanner,Bitcoin technical indicator screener"
            />
          </Head>
          <header
            className={`bg-[#f2efe4] h-14 sm:h-[55px] ${
              urlpathname == "/chart/" ? "hidden" : ""
            } `}
          >
            {/* <!-- navbar goes here --> */}
            <nav className="">
              <div className="max-w-6xl mx-auto px-4">
                <div className="flex justify-between">
                  <div className="flex space-x-4">
                    {/* <!-- logo --> */}
                    <div
                      onClick={() => {
                        setsortedResult([]);
                        setscreenedResultState([]);
                        settempList([]);
                        setbuttonType(true);
                        setsaved_screener_id(null);
                        router.push("/crypto-screener");
                      }}
                    >
                      <div
                        className="flex items-center py-3 sm:py-5 px-2 "
                      >
                        <div className="hidden sm:block">
                          <div className="btn btn-ghost normal-case text-xl text-black hover:bg-custom-background">
                          <label className="font-Bungee underline font-bold	text-[24px] cursor-pointer">
                            T
                          </label>
                          <label className="font-Bungee font-bold text-[24px] cursor-pointer">
                            RADE
                          </label>
                          <label className="font-Bungee underline font-bold	text-[24px] cursor-pointer">
                            P
                          </label>
                          <label className="font-Bungee font-bold text-[24px] cursor-pointer">
                            LAN.
                          </label>
                          <label className="font-Bungee underline font-bold text-[24px] cursor-pointer">
                            I
                          </label>
                          <label className="font-Bungee underline font-bold text-[24px] cursor-pointer">
                            O
                          </label>
                          <sup className=" font-Kaushan font-bold text-[14px] cursor-pointer">
                            Beta
                          </sup>
                          </div>
                        </div>
                        <div
                          className="sm:hidden h-9 w-8 mr-1 flex flex-grow"
                          onClick={() => {
                           setsaved_screener_id(null);
                            router.push("/crypto-screener");
                          }}
                          style={{
                            height: "1%",
                            width: "1%",
                          }}
                        >
                          <Image
                            src="/tplogoblack.png"
                            alt="Mobile logo"
                            width={40}
                            height={40}
                            // objectFit="contain"
                          />
                          {/* <img
                            src="/tplogoblack.png"
                            alt="Mobile logo"
                            width={40}
                            height={40}
                          /> */}
                          <div className="flex flex-grow">
                            <sup className=" font-Kaushan font-bold text-[14px] pt-[18px]">
                              Beta
                            </sup>
                          </div>
                        </div>
                       </div>
                    </div>
                    {/* <!-- primary nav --> */}
                    <div className="hidden md:flex items-center ">
                    <button
                      className={`py-5 px-3 pl-96 font-Manrope text-xl font-bold  `}
                    >
                      {" "}
                    </button>
                    <button
                      className={`py-5 px-3 pl-24 font-Manrope text-xl font-bold  `}
                    >
                      {" "}
                    </button>
                    <a
                      className={`btn btn-ghost normal-case font-Manrope text-xl font-bold text-black  ${
                        buttonType ? "underline" : ""
                      }`}
                      href="#"
                      onClick={(e) => {
                        settempList([]);
                        setbuttonType(true);
                        if (userLogin != null) {
                          setselectedScan("saved");
                        } else {
                          setselectedScan("popular");
                        }
                        router.push("/");
                      }}
                      title="Screener"
                    >
                      Screener
                    </a>
                    <a
                    href="#"
                      className={`btn btn-ghost normal-case font-Manrope text-xl text-black font-bold ${
                        buttonType ? "" : "underline"
                      }`}
                      onClick={(e) => {
                        settempList([]);
                        chooseCoin(); /*setbuttonType(false)*/
                      }}
                      title="Coins"
                    >
                      Coins
                    </a>{" "}
                    </div>
                  </div>
                  {/* <!-- secondary nav --> */}

                  <div className="hidden md:flex items-center space-x-1">
                    <button className="py-5 px-3"></button>
                    <a
                      href="#"
                      onClick={() => {
                        router.push("/login");
                      }}
                      className="btn btn-ghost normal-case font-Manrope text-xl font-bold text-black"
                      title="Sign In"
                    >
                      {userLogin != null
                        ? `Hi, ${userLogin.split(" ")[0]}`
                        : "Sign In"}
                    </a>
                  </div>
                  {/* <!-- mobile button goes here --> */}
                  <div className="md:hidden flex items-center">
                    <button
                      className="mobile-menu-button font-Manrope text-xl font-bold"
                      onClick={() => {
                        router.push("/login");
                      }}
                    >
                      {userLogin != null
                        ? `Hi, ${userLogin.split(" ")[0]}`
                        : "Sign In"}
                    </button>
                  </div>
                </div>
              </div>
              {/* <!-- mobile menu --> */}
            </nav>
          </header>

          <main className="bg-[#f2efe4] md:pt-4 pt-0">
            <Component {...pageProps} />
          </main>
          <div className={`${urlpathname == "/chart/" ? "hidden " : ""} `}>
            {/* _____________________________About Us___________________ */}
            {aboutus ? (
              <div>
                <div className="min-w-screen min-h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover">
                  <div className="absolute  opacity-80 inset-0 z-0"></div>
                  <div className="w-72 h-58  p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white">
                    {/*content*/}
                    <h3 className="font-medium pb-1">About Us</h3>

                    <div className="">
                      <div className="flex justify-center items-center "></div>
                      <div className="p-1"></div>
                      <div className="flex flex-col ...">
                        <div className="flex ...">
                          <div className="w-2/5 ... ">
                            <img
                              src="/tradeplan_founder.jpeg"
                              height={90}
                              width={90}
                              className="rounded-full"
                            ></img>
                          </div>
                          <div className="w-3/5 text-[15px] text-black font-semibold">
                            Shakil Mohammed
                            <br></br>
                            Founder
                            <a
                              className="w-1"
                              href="https://twitter.com/shakks?s=08"
                              target={"_blank"}
                              title="Fa Twitter"
                            >
                              <FontAwesomeIcon
                                icon={faTwitter}
                                className="text-blue-500 h-6"
                              ></FontAwesomeIcon>
                            </a>
                          </div>
                        </div>
                        <div className="p-2"></div>
                        <div className="flex ...">
                          <div className="w-2/5 ... ">
                            <img
                              src="/tradeplan_cto.jpg"
                              height={90}
                              width={90}
                              className="rounded-full"
                            ></img>
                          </div>
                          <div className="w-3/5 text-[15px] text-black font-semibold">
                            Sudheendra
                            <br></br>
                            CTO
                            <a
                              className="w-1"
                              href="https://twitter.com/SudheendraNitte?s=08"
                              target={"_blank"}
                              title="Fa Twitter CTO"
                            >
                              <FontAwesomeIcon
                                icon={faTwitter}
                                className="text-blue-500 h-6"
                              ></FontAwesomeIcon>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="p-1"></div>
                      <button
                        className="block w-full bg-white text-black border-2 border-black rounded-lg font-semibold h-12"
                        onClick={() => {
                          setaboutus(false);
                        }}
                      >
                        OK
                      </button>

                      {/* Desktop End */}
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </div>
            ) : null}
            {/* _____________________________About Us___________________ */}

            {/* _____________________________Privacy Poclicy__________________ */}
            {privacypolicy ? (
              <div>
                <div>
                  <div
                    className="min-w-screen h-3/6  pt-6 animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
                    onClick={() => setprivacypolicy(false)}
                  >
                    <div className="absolute  opacity-80 inset-0 z-0"></div>
                    <div className="w-80 sm:w-[600px]  p-2 relative mx-auto my-auto  rounded-xl shadow-lg overflow-y-auto h-[590px]  md:h-[600px] bg-white">
                      {/*content*/}

                      <h5 className="text-xl pl-2">Privacy Policy</h5>
                      <div className="p-2"></div>
                      <div className=" ">
                        <div className="text-[13px] leading-[22px]">
                          <Privacypolicy />
                        </div>
                        <div className="p-4"></div>
                        <button
                          className="block w-full bg-white text-black border-2 border-black rounded-lg font-semibold h-12"
                          onClick={() => {
                            setprivacypolicy(false);
                          }}
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>
                <div className="hidden min-w-screen min-h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover">
                  <div className="absolute  opacity-80 inset-0 z-0"></div>
                  <div className="w-[700px] h-20  p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white">
                    {/*content*/}
                    <h3 className="font-medium pb-1">Privacy Policy</h3>

                    <div className="">
                      <div className="flex justify-center items-center "></div>
                      <div className="p-1"></div>
                      <div className="text-sm overflow-auto"></div>
                      <div className="p-1"></div>
                      <button
                        className="block w-full bg-white text-black border-2 border-black rounded-lg font-semibold h-12"
                        onClick={() => {
                          setprivacypolicy(false);
                        }}
                      >
                        CANCEL
                      </button>

                      {/* Desktop End */}
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </div>
            ) : null}
            {/* _____________________________Privacy Policy___________________ */}

            {/* ___________________________TOS___________________ */}
            {tos ? (
              <div>
                <div>
                  <div
                    className="min-w-screen h-3/6  pt-6 animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
                    onClick={() => settos(false)}
                  >
                    <div className="absolute  opacity-80 inset-0 z-0"></div>
                    <div className="w-80 sm:w-[600px]  p-2 relative mx-auto my-auto  rounded-xl shadow-lg overflow-y-auto h-[590px]  md:h-[600px] bg-white">
                      {/*content*/}

                      <h5 className="text-xl pl-2">Terms Of Service</h5>
                      <div className="p-2"></div>
                      <div className=" ">
                        <div className="text-[13px] leading-[22px]">
                          <Tos />
                        </div>
                        <div className="p-4"></div>
                        <button
                          className="block w-full bg-white text-black border-2 border-black rounded-lg font-semibold h-12"
                          onClick={() => {
                            settos(false);
                          }}
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>

                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </div>
            ) : null}
            {/* __________________________TOS_________________ */}
            {/* _____________________________Plans__________________ */}
            {plans ? (
              <div>
                <div className="min-w-screen min-h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover">
                  <div className="absolute  opacity-80 inset-0 z-0"></div>
                  <div className="w-80 h-58  p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white">
                    {/*content*/}
                    <p className="font-medium text-xl pb-1">Subscriptions</p>

                    <div className="">
                      <div className="flex justify-center items-center "></div>
                      <div className="p-1"></div>
                      <div className="flex flex-row pt-4 pl-4 space-x-2">
                        <label className="text-xl text-[#56cda7] font-semibold">
                          PRO:
                        </label>
                        <label className="line-through text-xl font-semibold">
                          $129
                        </label>
                        <label className="text-[12px] pt-2 text-[#56cda7]">
                          FREE during BETA
                        </label>
                      </div>
                      <div className=" pl-4">
                        <label className="text-[14px]">
                          Unlimited Access to all PRO items
                        </label>
                      </div>
                      <div className="p-2"></div>

                      <div className="p-1"></div>
                      <button
                        className="block w-full bg-white text-black border-2 border-black rounded-lg font-semibold h-12"
                        onClick={() => {
                          setplans(false);
                        }}
                      >
                        OK
                      </button>

                      {/* Desktop End */}
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </div>
            ) : null}
            {/* _____________________________Plans___________________ */}

            <div className="h-20 cursor-pointer hidden ">
              <a
                href="https://forms.gle/gHpj7NXU8qDF4nYZ7"
                target={"_blank"}
                className="flex transform -rotate-90  float-right w-[100px] bg-[#56cda7] rounded-lg  text-white "
                title="disable-hidden-box"
              >
                {/* pl-[1460px]  */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                    clipRule="evenodd"
                  />
                </svg>
                FeedBack
              </a>
            </div>

            {/* <LazyLoad offset={100}> */}
            <div className="flex flex-col bg-[#f2efe4]">
              {/* min-min-h-screen */}
              <footer className="relative bg-blueGray-200 " 
              // style={{height: "100vh"}}
              >
                <div className=" flex justify-center items-center">
                  <div>
                    <ul className="flex justify-between space-x-4">
                      <li className="px-2 ">
                        <label
                          className="font-medium text-lg cursor-pointer underline"
                          onClick={() => {
                            setaboutus(true);
                          }}
                        >
                          About Us
                        </label>
                        <br></br>
                        <label
                          className="font-medium text-lg float-right text-right cursor-pointer underline"
                          onClick={() => {
                            settos(true);
                          }}
                        >
                          ToS
                        </label>
                      </li>
                      <li className="px-2">
                        <label
                          className="font-medium text-lg cursor-pointer underline"
                          onClick={() => {
                            setprivacypolicy(true);
                          }}
                        >
                          Privacy Policy
                        </label>
                        <br></br>
                        <label
                          className="font-medium text-lg cursor-pointer underline"
                          onClick={() => {
                            setplans(true);
                          }}
                        >
                          Plans
                        </label>
                      </li>
                      <li className="px-2">
                      {/* <a className='font-medium text-lg cursor-pointer underline'
                       href='https://medium.com/@shakks' target={"_blank"} aria-label="Blogs">Blogs</a> */}
                        <label
                          className="font-medium text-lg cursor-pointer underline"
                          onClick={() => {
                            router.push("/blogs");
                          }}
                        >
                          Blogs
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="p-1"></div>
                <FooterLayout />
                <div className=" container mx-auto p-8 sm:p-0"></div>
                <div className="m-4"></div>
              </footer>
            </div>
            {/* </LazyLoad> */}
            <div className="">
            <nav className="fixed bottom-0 sm:hidden inset-x-0 bg-white flex justify-between text-sm text-black-900  font-mono">
              {/* <Link href="/crypto-screener" passHref={true}> */}
              <span
                className="w-full block py-5 px-3 text-center"
                onClick={() => {
                  settempList([]);
                  if (userLogin != null) {
                    setselectedScan("saved");
                  } else {
                    setselectedScan("popular");
                  }
                  setsaved_screener_id(null);
                  router.push("/crypto-screener");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mx-auto "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
                Screener
              </span>
              {/* </Link> */}

              {/* <Link href="/coin" passHref={true}> */}
              <span
                className="w-full block py-5 px-3 text-center"
                onClick={() => {
                  settempList([]);
                  router.push("/coin");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
                Coins
              </span>
              {/* </Link> */}
            </nav>
            </div>
          </div>
        </Fragment>
      </AuthContextProvider>
    </AppContext.Provider>
  );
}

export default MyApp;
