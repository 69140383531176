import React, { useEffect, useState } from "react";
import moment from "moment";

const IdleTimeOutHandler = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isLogout, setLogout] = useState(false);
  let timer = undefined;
  const events = ["click", "load", "keydown", "keyup", "scroll"];
  const eventHandler = (eventType) => {
    if (!isLogout) {
      localStorage.setItem("lastInteractionTime", moment());
      if (timer) {
        props.onActive();
        startTimer();
      }
    }
  };

  useEffect(() => {
    addEvents();
    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, []);

  const startTimer = () => {
    if (timer) {
      props.onActive();
      localStorage.setItem("active", "1");
      clearTimeout(timer);
    }
    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        let timeOutInterval = props.timeOutInterval
          ? props.timeOutInterval
          : 300000;
        if (isLogout) {
          clearTimeout(timer);
        } else {
          if (diff._milliseconds < timeOutInterval) {
            startTimer();
            localStorage.setItem("active", "1");
            props.onActive();
          } else {
            props.onIdle();
            localStorage.setItem("active", "0");
            setShowModal(true);
          }
        }
      },
      props.timeOutInterval ? props.timeOutInterval : 300000
    );
  };
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });
    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };
  return <></>;
};

export default IdleTimeOutHandler;
