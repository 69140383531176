import axios from "axios";

import Constants from "../../helper/constants.json";

// https://test-exchangeinfo.tradeplancaprover.tradeplan.io/api/v2/exchangeInfo
// https://api.tradeplan.io/api/v2/exchangeInfo
const UserAPI = {
  binanceTicker: async (token) => {
    try {
      const response = await axios.get(`${Constants.BINANCE_TICKER_URL}`, {
        headers: {
          Authorization: window.localStorage.getItem("jwt_access_token"),
        },
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },
  hitbtcTicker: async (token) => {
    try {
      const response = await axios.get(`${Constants.HITBTC_TICKER_URL}`, {
        headers: {
          Authorization: window.localStorage.getItem("jwt_access_token"),
        },
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },
  exchangeInfo: async (token) => {
    // console.log("Exchange Info>>>>>>>>>>",token,"<<<<<<<<<<<<<");
    // console.log(window.localStorage.getItem('exchange_access_token'));
    // const headerData = { token: token,Authorization:`Bearer ${window.localStorage.getItem("access_token")}` };
    //  headers: {'Authorization': 'Bearer '+window.localStorage.getItem('exchange_access_token')}
    try {
      const response = await axios.get(`${Constants.EXCHANGE_INFO}`, {
        headers: {
          Authorization: window.localStorage.getItem("jwt_access_token"),
        },
      });
      // console.log("exchange Info");
      // console.log(response);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  savedScreener: async () => {
    const user: any = JSON.parse(window.localStorage.getItem("user"));
    let userId = "123";
    if (user) {
      userId = user["user_id"];
    }
    const headerData = {
      "x-api-key": "1O015YTC5GsJHrkCo7nc",
      userid: userId,
      Authorization: window.localStorage.getItem("jwt_access_token"),
    };
    // const headerData = {'x-api-key':'1O015YTC5GsJHrkCo7nc','userId':JSON.parse(window.localStorage.getItem("user"))["user_id"]};

    try {
      const response = await axios.get(`${Constants.SERVER_URL}/screeners`, {
        headers: headerData,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  SaveScreener: async (headersData, body) => {
    let header = headersData;
    header["Authorization"] = window.localStorage.getItem("jwt_access_token");
    console.log("saved screener", header);
    try {
      const response = await axios.post(
        `${Constants.SERVER_URL}/screeners`,
        body,
        {
          headers: headersData,
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  updateScreener: async (headerData, body, type) => {
    console.log("headerdata", headerData);
    let headers = {
      userid: headerData["userId"],
      Authorization: window.localStorage.getItem("jwt_access_token"),
    };
    let url = "";

    url = `${Constants.SERVER_URL}/screeners/${body["id"]}`;

    try {
      const response = await axios.patch(url, body, {
        headers: headers,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  deleteScreener: async (headerData, id) => {
    let headers = {
      userid: headerData["userId"],
      Authorization: window.localStorage.getItem("jwt_access_token"),
    };
    let url = "";
    url = `${Constants.SERVER_URL}/screeners/${id}`;
    try {
      const response = await axios.delete(url, {
        headers: headers,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  shareLink: async (headersData, body) => {
    headersData["Authorization"] =
      window.localStorage.getItem("jwt_access_token");
    let headers = {
      userid: headersData["userId"],
      Authorization: window.localStorage.getItem("jwt_access_token"),
    };
    try {
      const response = await axios.post(
        `${Constants.SHARE_LINK_URL}/urlshort`,
        body,
        {
          headers: headers,
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  shareLinkData: async (headers) => {
    headers["Authorization"] = window.localStorage.getItem("jwt_access_token");
    console.log("headerrs", headers);
    let header = {
      hexadecimalId: headers["hexadecimalId"],
      Authorization: window.localStorage.getItem("jwt_access_token"),
    };
    try {
      const response = await axios.get(`${Constants.SHARE_LINK_URL}/urlshort`, {
        headers: header,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  sharedURL: async (body) => {
    // headersData['Authorization'] = `Bearer ${window.localStorage.getItem("exchange_access_token")}`
    try {
      const response = await axios.post(
        `${Constants.SHARE_LINK_URL}/sharedURL`,
        body,
        {
          headers: {
            Authorization: window.localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  generateToken: async (body) => {
    try {
      var userId = "123";
      if (window.localStorage.getItem("user")) {
        const user: any = JSON.parse(window.localStorage.getItem("user"));
        if (userId) {
          userId = user["user_id"];
        }
      }
      body["user_id"] = userId;
      const response = await axios.post(`${Constants.TOKEN_URL}`, body, {
        headers: { "x-api-key": "1O015YTC5GsJHrkCo7nc", userid: userId },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  runScreener: async (body) => {
    try {
      var userId = "123";
      if (window.localStorage.getItem("user")) {
        const user: any = JSON.parse(window.localStorage.getItem("user"));
        if (userId) {
          userId = user["user_id"];
        }
      }
      let statemachine_para = {
        input: JSON.stringify(body),
        name: "Random1",
        stateMachineArn:
          "arn:aws:states:ap-south-1:696773726879:stateMachine:test-alert-machine",
      };
      body["user_id"] = userId;
      const response = await axios.post(
        `${Constants.RESULT_URL}/screener`,
        statemachine_para
      );

      return response;
    } catch (error) {
      // console.log("errr",error);
      return error.response;
    }
  },
  getALLData: async (token) => {
    try {
      const response = await axios.get(`${Constants.EXCHANE_URL}`, {
        headers: {
          token: token,
          Authorization: window.localStorage.getItem("jwt_access_token"),
        },
      });

      return response;
    } catch (error) {
      return error.response;
    }
  },
  generateAWTToken: async () => {
    try {
      var body = { username: "screener", password: "screener" };
      var response = axios.post(`${Constants.SCREENER_TOKEN_URL}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  generateexchangeAWTToken: async () => {
    try {
      var body = { username: "exchange", password: "exchange" };

      var response = axios.post(`${Constants.EXCHANGE_TOKEN_URL}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  savedAlert: async () => {
    const user: any = JSON.parse(window.localStorage.getItem("user"));
    let userId = "123";
    if (user) {
      userId = user["user_id"];
    }
    const headerData = {
      // "x-api-key": "1O015YTC5GsJHrkCo7nc",
      userid: userId,
      Authorization: window.localStorage.getItem("jwt_access_token"),
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "GET",
    };
    // const headerData = {'x-api-key':'1O015YTC5GsJHrkCo7nc','userId':JSON.parse(window.localStorage.getItem("user"))["user_id"]};

    try {
      const response = await axios.get(
        `${Constants.ALERT_URL}/get_alert_screener`,
        {
          headers: headerData,
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  alertScreener: async (body) => {
    try {
      var userId = "123";
      if (window.localStorage.getItem("user")) {
        const user: any = JSON.parse(window.localStorage.getItem("user"));
        if (userId) {
          userId = user["user_id"];
        }
      }
      body["user_id"] = userId;
      const response = await axios.post(
        `${Constants.ALERT_URL}/insert_alert_screener`,
        body,
        {
          headers: {
            "x-api-key": "1O015YTC5GsJHrkCo7nc",
            userid: userId,
            Authorization: window.localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  deleteAlertScreener: async (headerData, id) => {
    let header = headerData;
    header["Authorization"] = window.localStorage.getItem("jwt_access_token");
    let url = "";
    url = `${Constants.ALERT_URL}/alert_screener/${id}`;
    try {
      const response = await axios.delete(url, {
        headers: header,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  updateAlertScreener: async (headerData, body) => {
    let url = "";
    let header = headerData;
    header["Authorization"] = window.localStorage.getItem("jwt_access_token");
    url = `${Constants.ALERT_URL}/alert_screener/${body["id"]}`;

    try {
      const response = await axios.patch(url, body, {
        headers: header,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  alertToggle: async (body) => {
    try {
      var userId = "123";
      if (window.localStorage.getItem("user")) {
        const user: any = JSON.parse(window.localStorage.getItem("user"));
        if (userId) {
          userId = user["user_id"];
        }
      }
      body["user_id"] = userId;
      const response = await axios.post(
        `${Constants.ALERT_URL}/alert_screener/${body.alert_screener_id}`,
        body,
        {
          headers: {
            "x-api-key": "1O015YTC5GsJHrkCo7nc",
            userid: userId,
            Authorization: window.localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  savedAlertNotifications: async (bodyData) => {
    const user: any = JSON.parse(window.localStorage.getItem("user"));
    let userId = "123";
    if (user) {
      userId = user["user_id"];
    }

    const config = {
      headers: {
        "x-api-key": "1O015YTC5GsJHrkCo7nc",

        userid: userId,
      },
    };
    const headerData = {
      // "x-api-key": "1O015YTC5GsJHrkCo7nc",
      userid: userId,
      Authorization: window.localStorage.getItem("jwt_access_token"),
      // "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json",
    };
    // const headerData = {'x-api-key':'1O015YTC5GsJHrkCo7nc','userId':JSON.parse(window.localStorage.getItem("user"))["user_id"]};
    // var body = {
    //   alert_screener_id: alert_screener_id,
    // };
    try {
      const response = await axios.post(
        `${Constants.ALERT_URL}/get_alert_notifications`,
        bodyData,
        {
          headers: headerData,
        }
      );
      /* const response = await axios.get(
        `${Constants.ALERT_URL}/get_alert_notifications`,
        config
      );*/
      return response;
    } catch (error) {
      return error.response;
    }
  },
  savedAlertNotificationsTrack: async (alert_screener_id) => {
    const user: any = JSON.parse(window.localStorage.getItem("user"));
    let userId = "123";
    if (user) {
      userId = user["user_id"];
    }

    const config = {
      headers: {
        // "x-api-key": "1O015YTC5GsJHrkCo7nc",

        userid: userId,
        alert_screener_id: alert_screener_id,
      },
    };
    const headerData = {
      // "x-api-key": "1O015YTC5GsJHrkCo7nc",
      userid: userId,
      Authorization: window.localStorage.getItem("jwt_access_token"),
      // "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json",
    };
    // const headerData = {'x-api-key':'1O015YTC5GsJHrkCo7nc','userId':JSON.parse(window.localStorage.getItem("user"))["user_id"]};

    try {
      const response = await axios.get(
        `${Constants.ALERT_URL}/get_alert_notifications_track`,
        config
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getsavedAlerts: async (bodyData) => {
    const user: any = JSON.parse(window.localStorage.getItem("user"));
    let userId = "123";
    if (user) {
      userId = user["user_id"];
    }
    const headerData = {
      // "x-api-key": "1O015YTC5GsJHrkCo7nc",
      userid: userId,
      Authorization: window.localStorage.getItem("jwt_access_token"),
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "POST",
    };
    // const headerData = {'x-api-key':'1O015YTC5GsJHrkCo7nc','userId':JSON.parse(window.localStorage.getItem("user"))["user_id"]};

    try {
      const response = await axios.post(
        `${Constants.ALERT_URL}/saved_alerts`,
        bodyData,
        {
          headers: headerData,
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  deltaResults: async (body, datetimeInteger) => {
   
    const currDate = new Date().toLocaleDateString;
    const currTime = new Date().toLocaleTimeString;
    console.log(currDate, "////", currTime)
    let URL = "https://www.binance.com/api/v3/klines?symbol="+body["symbol"]+"&interval=1m&limit=1&endTime="+datetimeInteger
    try {
      const response = await axios.get(
        `${URL}`,
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  savedScreenerResult: async (screener_id) =>{
    const headerData = {
      Authorization: window.localStorage.getItem("jwt_access_token"),
    };
    try {
      const response = await axios.post(
        `${Constants.SERVER_URL}/saved_screeners`,
        {
          "id": screener_id
        },
        {
          headers: headerData,
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }
};

export default UserAPI;